import { Controller } from "stimulus"
import Cropper from 'cropperjs'

export default class extends Controller {
    static targets = ["output"]

    connect() {
        console.log('ideas#connect')

        const image = document.getElementById('#mainImage');
        const cropper = new Cropper(image, {
            aspectRatio: 16 / 9,
            crop(event) {
                console.log(event.detail.x);
                console.log(event.detail.y);
                console.log(event.detail.width);
                console.log(event.detail.height);
                console.log(event.detail.rotate);
                console.log(event.detail.scaleX);
                console.log(event.detail.scaleY);
            },
        });
    }
}