export function submitComment(url, data) {
  return new Promise((resolve, reject) => {
    $.post(url, {
        comment: data
    }, (resp) => {
        resolve(resp);
    })
  })
}

export function loadBoardComments(url) {
  $('#canvasRight .comments').load(url, () => {
    WC.boards.bindComments();  

    const $rightTop = $('#canvasRight .top');
    $rightTop.scrollTop($rightTop.prop('scrollHeight'));
  })
}