import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["output"]

    connect() {
        console.log('professionals#connect')
    }

    showForm(ev) {
        ev.preventDefault();

        document.getElementById('whichSection').classList.add('hidden')
        document.getElementById('youtubeWarning').classList.add('hidden')
        document.getElementById('signupSection').classList.remove('hidden')

        var self = this;
        $('#professional_how_heard').on('change', function(ev) {
            if (this.value.toLowerCase().includes('canva')) {
                self.showYouTubeMessage();
                document.getElementById('youtubeWarning').classList.remove('hidden')
                document.getElementById('actionRow').classList.add('hidden')

                $('#youtubeConfirmCheckbox').on('change', function() {
                    if (this.checked) {
                        document.getElementById('actionRow').classList.remove('hidden');
                    }
                })
            } else {
                document.getElementById('youtubeWarning').classList.add('hidden')
                document.getElementById('actionRow').classList.remove('hidden')                
            }
        })
    }    

    showYouTubeMessage() {
        $('#youtubeCanvaModal').modal('show');
    }
}

