import { Controller } from "stimulus"

export default class extends Controller {

    connect() {
        console.log('billing#connect')
    }

    show(ev) {
        ev.preventDefault();

        document.getElementById('promoCode').classList.remove('hidden');
        document.getElementById('promoLink').classList.add('hidden');
    }

    changePlan(ev) {
        var new_plan = ev.currentTarget.value;
        document.getElementById('plan_name').value = new_plan;
    }

}