import { Controller } from "stimulus"

export default class extends Controller {

    connect() {
        console.log('board-status#connect')
    }

    remove(ev) {
        $(ev.currentTarget).closest('.row').remove();
    }

}