// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "terms", "btn"]

  connect() {
   console.log('payments#connect', this.termsTarget.checked)
  }

  checked(ev) {
    console.log('checked', this.termsTarget.checked)
   if (this.termsTarget.checked) {
    this.btnTarget.removeAttribute('disabled')
    this.btnTarget.classList.remove('disabled')
   } else {
    this.btnTarget.setAttribute('disabled', true)
    this.btnTarget.classList.add('disabled')
   }
  }

  setup(ev) {
    if (this.termsTarget.checked) {
      return true 
    } else {
      alert('Please accept the the Wecora Payment terms above to continue');
      return false
    }
  }
}
