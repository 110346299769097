import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["output"]

    connect() {
        console.log('boards#connect')
    }

    showPL(ev) {
        $('.pl_report').trigger('click')
    }
}