// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

const maxFilesize = 25;
const maxSimultaneousFiles = 6;

export default class extends Controller {
    static targets = ["nextPage", "boards"];
    static values = { url: String, comments: String };

    connect() {
        console.log('messages#connect', this.urlValue)
        Intercom('update', { 'hide_default_launcher': true });
        this.loadBoard(this.urlValue, this.commentsValue);

        document.body.addEventListener("comment_created", ev => {
            this.addComment(ev);
        });
    }

    select(ev) {
        ev.preventDefault();
        const url = ev.params.url;
        const commentsUrl = ev.params.comments;
        const dot = ev.currentTarget.querySelector('.dot');

        $('.boards li').removeClass('selected');
        ev.currentTarget.classList.add('selected');
        if (dot) {
            dot.classList.add('hidden');
        }

        this.loadBoard(url, commentsUrl);
    }

    loadBoard(url, commentsUrl) {
        if (url != '' && commentsUrl != '') {
            $('#content').load(url, resp => {
                const $comments = $('#content .comments')
                $comments.load(commentsUrl, resp => {
                    bindCommentForm($('form#newCommentForm'));
                    this.bindDelete();
                    $comments.imagesLoaded(() => {
                        if (this.hasNextPageTarget && $comments.scrollTop() < 100) {
                            // need to do this in case the last page isnt triggering a scroll
                            this.nextPage(commentsUrl, true);
                        } else {
                            const lastComment = document.getElementById('content').querySelector('.comment:last-child');
                            if (lastComment) {
                                lastComment.scrollIntoView();
                            }
                        }
                    });
                });
            });
        }
    }

    nextPage(commentsUrl, scrollToBottom) {
        if (!this.nextPageTarget.value) return;

        this.loading = true;
        var $comments = $('#content .comments')
        commentsUrl += '?page=' + this.nextPageTarget.value;
        $.get(commentsUrl, resp => {
            $comments.prepend(resp).imagesLoaded(() => {
                // now we need to jump the scroll to prevent another loading event
                if (scrollToBottom) {
                    document.getElementById('content').querySelector('.comment:last-child').scrollIntoView();
                } else {
                    $comments.scrollTop(200);
                }
                this.loading = false;
            });
        });
    }

    scroll(ev) {
        if (ev.target.scrollTop < 100 && !this.loading) {
            this.nextPage(ev.params.comments, false);
        }
    }

    addComment(e) {
        document.getElementById('newCommentForm').reset();

        const $comments = $('#content .comments');
        const html = e.detail.html;
        const lastComment = $('#content .comments .comment:last')
        var date1 = new Date();
        if (lastComment && lastComment.data() !== undefined) {
            date1 = new Date(lastComment.data().commentDate);
        }
        const date2 = new Date(e.detail.date);
        const diffTime = Math.abs(date2 - date1);
        const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        var header;
        if (diffDays == 0) {
            if (diffHours > 0) {
                header = `Today ${date2.toLocaleTimeString()}`
            }
        } else {
            header = `Today ${date2.toLocaleTimeString()}`
        }

        if (header) {
            const div = document.createElement('div');
            div.classList.add('date');
            div.innerHTML = header;
            $comments.append(div);
        }

        $comments.append(html).imagesLoaded(() => {
            document.getElementById('content').querySelector('.comment:last-child').scrollIntoView();
            this.bindDelete()
        });

        // rearrange board list
        const $board = $('#sideBar .boards .selected');
        const $boardDate = $board.find('.date');
        $board.insertBefore($('#sideBar .boards li:first'));
        $boardDate.text(date2.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }))
    }

    bindDelete() {
        const $delete = $('#content .comments .delete')
        $delete.off('click')
        $delete.on('click', function(ev) {
            const url = $delete.data().deleteUrl;
            $.ajax({
                url: url,
                method: 'DELETE'
            }).done(resp => {
                $(this).closest('.comment').fadeOut();
            })
        })
    }

    filter(ev) {
        this.boardsTargets.forEach(el => {
            if (!el.dataset.name.toLowerCase().includes(ev.target.value.toLowerCase())) {
                el.style.display = 'none';
            } else {
                el.style.display = 'block';
            }
        })
    }

    share(ev) {
        ev.preventDefault()

        const $modal = $("<div id='shareModalBoard' class='modal fade'><div class='modal-dialog'><div class='modal-content'></div></div></div>");
        const shareUrl = ev.params.url;

        $modal.on('shown.bs.modal', function(e) {
            $modal.find('.modal-content').load(shareUrl, function() {
                WC.boards.bindShareModal($modal);
            });
        });

        $modal.on('hidden.bs.modal', function(e) {
            $modal.remove();
        });

        $modal.modal('show');

    }
}

var bindCommentForm = function($commentForm) {
    if (_.isUndefined($commentForm) || ($commentForm.length === 0)) { return; }

    const $commentInput = $commentForm.find('#comment_comment');
    $commentInput.focus();
    $commentForm.find('.submit_comment_btn').prop('disabled', false);

    if (!$commentForm.get(0).dropzone) {
        return $commentForm.dropzone({
            maxFilesize,
            maxFiles: maxSimultaneousFiles,
            clickable: $commentForm.find('.dz-message').get(0),
            previewsContainer: $(".preview_zone").get(0),
            addRemoveLinks: true,
            uploadMultiple: true,
            parallelUploads: 6,
            autoProcessQueue: false,

            init() {
                const dropzone = this;

                $commentForm.submit(function(ev) {
                    ev.preventDefault();
                    ev.stopPropagation();

                    if (dropzone.files.length > 0) {
                        return dropzone.processQueue();
                    } else {
                        const $this = $(this);
                        if ($this.find('textarea').val().trim() === '') { return; }

                        return $.post($this.attr('action'), $this.serialize());
                    }
                });

                this.on('addedfile', function(file) {
                    const bHeight = $('#canvasRight .bottom').height();
                    $('#canvasRight .bottom').height(bHeight + 200);
                    $('#canvasRight .top').css('bottom', bHeight + 208);
                    return $commentForm.find('.submit_comment_btn').prop('disabled', false);
                });

                this.on('success', response => eval(response.xhr.response));

                this.on("complete", function(file) {
                    return this.removeFile(file);
                });

                return this.on("canceled", function(file) {
                    return this.removeFile(file);
                });
            }
        });
    }
};