// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
import { submitComment, loadBoardComments } from "../modules/comments";

export default class extends Controller {
    static targets = ["output", "comment", "comments"]
    static values = {
        boardUrl: String,
        tileUrl: String,
        referenceId: Number,
        referenceType: String,
        commentorId: Number,
        commentorType: String,
        url: String,
        publicView: Boolean
    }

    connect() {
        // console.log('comments#connect - value', this.urlValue)
    }

    submit(ev) {
        ev.preventDefault();

        if (this.commentTarget.value == "") {
            this.commentTarget.focus();
            return;
        } else {
            ev.target.innerHTML = "...";
        }

        const data = {
            comment: this.commentTarget.value,
            reference_id: this.referenceIdValue,
            reference_type: this.referenceTypeValue,
            commentor_id: this.commentorIdValue,
            commentor_type: this.commentorTypeValue,
            socket_id: ''
        }

        submitComment(this.urlValue + '.html', data)
            .then((resp) => {
                const newDiv = document.createElement('div');
                newDiv.innerHTML = resp;
                this.commentsTarget.append(newDiv);
                $(this.commentsTarget).scrollTop(this.commentsTarget.scrollHeight);

                // clean comment
                this.commentTarget.value = '';
                this.commentTarget.focus();
                ev.target.innerHTML = "Post";

                // update counts
                let $count = $('#modalIdeaDetails .comments_count');
                if ($count !== undefined) {
                    let count = Number($count.text());
                    let newCount = (count + 1).toString()
                    $count.html(newCount);
                }

                // reload the tile
                const $tile = $(`#idea_${this.referenceIdValue} .tile_comments`)
                const $boardContainer = $('#tileBoard')

                $tile.load(this.tileUrlValue, () => {
                    WC.boards.tile.reshift($boardContainer);

                    if (this.publicViewValue) {
                        console.log('rebinding public');
                        WC.boards.tile.rebindPublic($boardContainer);
                    }

                    loadBoardComments(this.boardUrlValue);
                })
            });
    }

    deleteComment(ev) {
        ev.preventDefault()
        var $this = $(ev.target),
            $comment = $this.closest('.comment');

        var url = ev.target.dataset.deleteUrl;
        if (url) {
            $comment.fadeOut('fast', function() {
                $comment.remove();
                $.post(url, { '_method': 'delete' });
            })
        }
    }
}