import { Controller } from "stimulus"

export default class extends Controller {
    static values = { state: String, user: String }

    connect() {
        console.log('canva#connect', this.stateValue)

        var self, $form, $frame, $logoutLink;

        self = this;
        $frame = $("body");
        $form = $('#canvaLoginForm');
        $logoutLink = $('#logoutLink');

        $form.on("ajax:success", function(event) {
            let data = event.originalEvent.detail[0];
            let status = event.originalEvent.detail[1];
            let xhr = event.originalEvent.detail[2];
            $('#error').addClass('hidden');
            if (data.id !== undefined) {
                $('#loginForm').hide();
                $('#success').removeClass('hidden');
                location.href = `/canva/authorize?state=${self.stateValue}&user=${self.userValue}`;    
            } else {
                $('#error').removeClass('hidden');
            }
        });

        $form.bind("ajax:error", function(event) {
            console.log('error', event)
            return showAlert($frame, event.originalEvent.detail[0], 'danger');
        });

        $logoutLink.on("ajax:success", function(event) {
            window.close();
        })

        $('.cancel').on('click', function(event) {
            event.preventDefault();
            window.close();
        })
    }

    showAlert($frame, msg, alertType) {
        var $alert;
        $alert = $frame.find('#alerts');
        $alert.removeClass().addClass('alert alert-' + alertType);
        $alert.html(msg);
        $alert.click(function(ev) {
            return $alert.slideUp();
        });
        $alert.delay(5000).slideUp();
        $frame.find('.please_wait').hide();
    };
}