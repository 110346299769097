import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["output"]

    connect() {
        console.log('demo#connect')

        window.addEventListener("message", function(e) {
            if (isCalendlyEvent(e)) {
              let testimonial = document.getElementById('testimonials');
              let calendly = document.getElementById('calendly-widget');

              if (e.data.event == 'calendly.event_type_viewed') {
                testimonial.classList.remove('hidden');
                calendly.classList.remove('lg');
              }
              if (e.data.event == 'calendly.date_and_time_selected') {
                testimonial.classList.add('hidden');
                calendly.classList.add('lg');
              }
            }
        });

    }
}

function isCalendlyEvent(e) {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
};