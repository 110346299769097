import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["output"]

    connect() {
        console.log('projects#connect')
    }

    toggleFolder(ev) {
        ev.preventDefault();

        $(ev.currentTarget).closest('td').find('.boards').toggleClass('hidden');
    }

    toggleAllBoards(ev) {
        ev.preventDefault();
        $('td .boards').toggleClass('hidden');
    }
}