import { Controller } from "stimulus"
import Cropper from 'cropperjs'

export default class extends Controller {
    static targets = ["form", "content", "textarea"]

    connect() {
        console.log('notes#connect')
    }

    edit() {
        console.log('edit')
        this.formTarget.classList.remove('hidden');
        this.contentTarget.classList.add('hidden');
        this.textareaTarget.focus();

        this.textareaTarget.style.height = "1px";
        this.textareaTarget.style.height = (25 + this.textareaTarget.scrollHeight) + "px";
    }
}